
import {Component, Mixins, Watch} from "vue-property-decorator";
import Dates from "@/components/inputs/Dates.vue";
import TakipTipiPicker from "@/components/pickers/TakipTipiPicker.vue";
import TakipAltTipiPicker from "@/components/pickers/TakipAltTipiPicker.vue";
import TakipYoluPicker from "@/components/pickers/TakipYoluPicker.vue";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import {TakipYolu} from "@/enum/TakipYolu";
import MahiyetPicker from "@/components/pickers/MahiyetPicker.vue";
import {TakipTipi} from "@/enum/TakipTipi";

@Component({
  components: {
    MahiyetPicker,
    Dates,
    TakipTipiPicker,
    TakipAltTipiPicker,
    TakipYoluPicker,
  },
})
export default class TakipTalebiForm extends Mixins(SimpleInputMixin) {
  takipYolu = TakipYolu;
  takipTipi = TakipTipi;

  @Watch('localValue.takip_tipi_id')
  onTakipTipiChange() {
    if (!(this.mahiyetVarMi))
      this.localValue.takip_mahiyeti_id = null;
  }
  get mahiyetVarMi() {
    return (this.localValue.takip_tipi_id == this.takipTipi.ilamsiz || this.localValue.takip_tipi_id == this.takipTipi.paraBorcuVeyaTeminat);
  }

}
