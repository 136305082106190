import {AlacakKalemiTuru} from "@/enum/AlacakKalemiTuru";
import {ParaBirimiEntity} from "@/entity/ParaBirimiEntity";
import {SozlesmeEntity} from "@/entity/AlacakBelgeler/SozlesmeEntity";
import {NafakaEntity} from "@/entity/AlacakBelgeler/NafakaEntity";
import {NafakaTuru, NafakaTuruLabel} from "@/enum/NafakaTuru";
import {SozlesmeTuru} from "@/enum/SozlesmeTuru";
import {AlacakBelgeTuruEntity} from "@/entity/AlacakBelgeTuruEntity";
import {IhtarEntity} from "@/entity/AlacakBelgeler/IhtarEntity";
import {ProtestoEntity} from "@/entity/AlacakBelgeler/ProtestoEntity";
import {BankaHesabiEntity} from "@/entity/BankaHesabiEntity";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {MahkemeEntity} from "@/entity/MahkemeEntity";
import {NoterEntity} from "@/entity/NoterEntity";
import {DovizTakipSekli} from "@/enum/DovizTakipSekli";
import {AdresTuru} from "@/enum/AdresTuru";
import {IlceEntity} from "@/entity/IlceEntity";
import {IlEntity} from "@/entity/IlEntity";
import {TasinirEntity} from "@/entity/AlacakBelgeler/TasinirEntity";
import {TasinmazEntity} from "@/entity/Haciz/TasinmazEntity";
import {TeminatTuru} from "@/enum/TeminatTuru";

export class AlacakEntity {
    id!: number;
    aciklama!: string;
    adres_detayi!: string;
    adres_turu!: AdresTuru;
    adres_turu_id!: number;
    alacak_belge_turu!: AlacakBelgeTuruEntity;
    alacak_belge_turu_id!: number;
    alacak_kalemleri: Array<AlacakKalemiEntity> = [];
    asil_alacaklar: Array<AlacakKalemiEntity> = [];
    banka_adi !: string;
    banka_hesabi_id!: number;
    banka_hesap_numarasi !: string;
    belge_aciklamasi!: string;
    belge_tarihi!: Date;
    bir_yillik_kira_bedeli!: number;
    borcluya_verilen_mehlil_muddeti!: any;
    cek_seri_no!: string;
    cocuk_adi_soyadi!: string;
    cocuk_tc_no!: number;
    dava_acilis_tarihi!: Date;
    diger_alacak_no!: number;
    dosya_turu!: any;
    dosya_turu_id!: number;
    doviz_kur_tarihi!: DovizTakipSekli | null;
    esas_no!: number;
    faiz_turu_id!: number;
    hazirlanis_tarihi!: Date;
    icra_inkar_tazminati!: number;
    icranin_geri_birakilmasi_karari_alindi_mi!: boolean;
    icranin_geri_birakilmasi_var_mi!: boolean;
    ihtar_var_mi: boolean = false;
    kontrat_aciklamasi!: string;
    ihtarlar!: Array<IhtarEntity>;
    ilam_ozeti!: string;
    ilam_tipi!: any;
    ilami_veren_kurum!: string;
    ilce!: IlceEntity;
    ilce_id!: number;
    islem_baslasin_mi: boolean = true;
    karar_no!: number;
    dosya_no!: number;
    karar_tarihi!: Date;
    kefil_var_mi: boolean = false;
    keside_tarihi!: Date;
    keside_yeri!: string;
    kesideci!: string;
    kesinlesme_tarihi!: Date;
    kur!: number;
    lehtar!: string;
    mahkeme!: MahkemeEntity;
    mahkeme_id!: number;
    mahkeme_vekalet_ucreti!: number;
    muacceliyet_var_mi: boolean = false;
    muacceliyet_tarihi!: Date;
    nafaka_turu!: NafakaTuru;
    noter!: NoterEntity
    noter_id!: number;
    odeme_yeri!: number;
    odenecek_kisi!: string;
    olmasi_gereken_pul_degeri!: string;
    para_birimi!: ParaBirimiEntity;
    para_birimi_id!: number;
    para_ile_olculen_alacak_var_mi!: boolean;
    protesto_var_mi: boolean = false;
    protestolar!: Array<ProtestoEntity>;
    sehir!: IlEntity;
    sehir_id!: number;
    senet_tanzim_yeri!: string;
    sozlesme_baslangic_tarihi!: Date;
    sozlesme_bitis_tarihi!: Date;
    sozlesme_ismi!: string;
    sozlesme_tarihi!: Date;
    sozlesme_turu!: SozlesmeTuru;
    tah_mak_no!: number;
    tah_mak_tarihi!: Date;
    tahliye_taahhut_imza_tarihi!: Date;
    tahliye_tarihi!: Date;
    takibe_eklenecek_tutar!: number;
    takip_id!: number;
    takip_talebi_id!: number;
    tanzim_tarihi!: Date;
    tasinirlar!: Array<TasinirEntity>;
    tasinmazlar!: Array<TasinmazEntity>;
    teminat_aciklamasi!: string;
    teminat_nedeni!: number;
    teminat_no!: number;
    teminat_turu!: TeminatTuru;
    teminat_turu_id!: number;
    teminat_tutari!: number;
    teminat_tutari_para_birimi!: ParaBirimiEntity;
    teminat_tutari_para_birimi_id!: number;
    teminat_var_mi: boolean = false;
    teminat_veren_kurum!: string;
    tutar!: number;
    uzerindeki_pul_degeri!: string;
    yargilama_gideri!: number;
    yevmiye_no!: number;
    takip_tarihli_kurlar!: any
}

